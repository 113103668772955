import { mapGetters } from 'vuex';
import dashboardTaskListMixin from '@/mixins/dashboardTaskList';

import { PROFILE_KEY_WIZARD_AB_TEST } from '@/config/constants';
import { FEATURE_CONSTANTS } from '@/utils/features';

const ORIGINAL_FLOW = 'A';
const NON_ORIGINAL_FLOW = 'B';

export default {
  mixins: [dashboardTaskListMixin],
  data() {
    return {
      originalFlow: ORIGINAL_FLOW,
    };
  },
  computed: {
    ...mapGetters(['wizardPreferences', 'userProfileWizardAbTest', 'hasAccountFeature']),
    isOriginalFlow() {
      if (this.hasFeatureFlag()) {
        return this.originalFlow === ORIGINAL_FLOW;
      }

      return true;
    },
  },
  methods: {
    random() {
      const randomBit = Math.round(Math.random());
      return randomBit === 0 ? NON_ORIGINAL_FLOW : ORIGINAL_FLOW;
    },
    hasFeatureFlag() {
      return this.hasAccountFeature(FEATURE_CONSTANTS.WIZARD_AB_TEST);
    },
    setAbTestParam() {
      const params = new URLSearchParams(window.location.search);

      if (!params.has('abTest')) {
        params.set('abTest', this.originalFlow);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      }
    },
  },
  async created() {
    if (!this.hasFeatureFlag()) return;

    if (this.userProfileWizardAbTest) {
      this.originalFlow = this.userProfileWizardAbTest;
    } else if (!this.userProfileWizardAbTest && this.wizardPreferences.skip) {
      this.originalFlow = ORIGINAL_FLOW;
    } else {
      const randomGeneratedFlow = this.random();
      this.originalFlow = randomGeneratedFlow;
      await this.setToUserProfile(PROFILE_KEY_WIZARD_AB_TEST, randomGeneratedFlow);
    }

    this.setAbTestParam();
  },
};
